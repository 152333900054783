.react-datepicker-wrapper{
    width:100%;
}

.react-datepicker-popper .react-datepicker{
    border:none;
}

.react-datepicker-popper .react-datepicker::after{
    content:"";
    width:90%;
    height:100%;
    transform: translateX(-50%);
    left:50%;
    position: absolute;
    box-shadow: 0px 10px 30px rgba(0,0,0,0.2);
    z-index: -1;
}


.react-datepicker-popper{
    width:100%;
    box-sizing: border-box;
    padding-right:2.8em;
}

@media(min-width:420px){
    .react-datepicker-popper{
        padding-right:5.6em;
    }
}

.react-datepicker__day-names, .react-datepicker__week {
    display: flex;
    justify-content: space-around;
}


.react-datepicker, .react-datepicker__month-container, .react-datepicker__input-container{
    width:100%;
}

.react-datepicker__triangle{
    display:none;
}

.react-datepicker__header{
    border-bottom:none;
}


.react-datepicker__day--selected{
    background-color:#3c4249;
}

.react-datepicker__day--keyboard-selected{
    background-color:#57616b;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--selected:hover{
    background-color:#343a40;
}