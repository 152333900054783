
.id-modal{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items:center;
    padding:11px;
    .close-button{
        position: absolute;
        top:0;
        right:0;
        margin:5px;
        padding:5px 10px;
    }
}